@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@layer utilities {

   .arrowDown {
       clip-path: polygon(100% 0, 0 0, 50% 37%);
       /* filter:drop-shadow(5px 10px 5px rgba(0,0,0,0.7)); */
   }

   .parent:hover .child{
    display: block !important;
   }

}

.shrinkToTwoLines{
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis
}

.shrinkToOneLine{
-webkit-line-clamp: 1 !important;
}

.numberClass::-webkit-outer-spin-button,
.numberClass::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.toggle {
    --width: 55px;
    --height: calc(var(--width) / 2.0);
    position: relative;
    display: inline-block;
    width: var(--width);
    height: var(--height);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
    border-radius: var(--height);
    cursor: pointer;
}

/* hiding checkbox */
.toggle input {
    display: none;
}

.toggle .slider {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: var(--height);
    background-color: gray;
    transition: all 0.4s ease-in-out;
}

.toggle .slider::before {
    content: '';
    position: absolute;
    top: 2px;
    left: 1px;
    width: calc(var(--height) * 0.9);
    height: 85%;
    border-radius: calc(var(--height) / 1);
    background-color: #fff;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
    transition: all 0.4s ease-in-out;
}

/* Adding slider effect */
.toggle input:checked + .slider {
    background-color: #267DF8;
}
.toggle input:checked + .slider::before {
    transform: translateX(calc(var(--width) - var(--height)));
}

.toggle .labels {
    position: absolute;
    top: 3px;
    left: 2px;
    width: 100%;
    height: 100%;
    font-weight: bold;
    font-size: 13px;
    transition: all 0.4s ease-in-out;
}

.toggle .labels::after {
    content: attr(data-off);
    position: absolute;
    right: 10px;
    color: #fff;
    opacity: 1;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
    transition: all 0.4s ease-in-out;
}

.toggle .labels::before {
    content: attr(data-on);
    position: absolute;
    left: 5px;
    color: #ffffff;
    opacity: 0;
    text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.4);
    transition: all 0.4s ease-in-out;
}

.toggle input:checked ~ .labels::after {
    opacity: 0;
}

.toggle input:checked ~ .labels::before {
    opacity: 1;
}

.numberClass::-webkit-outer-spin-button,
.numberClass::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}